<template>
  <container-list-extend class="d-flex flex-column w-100" @toggle="desc = !desc">
    <template v-slot:title>
      <div class="d-flex flex-row flex-grow-1 p-2 align-items-center">
        <BaseIcon v-if="file.type_id == 1" name="folder" color="#ffc107" width="25" height="25"></BaseIcon>
        <BaseIcon v-if="file.type_id == 2" name="file" color="#007bff" width="25" height="25" class></BaseIcon>
        <BaseIcon v-if="file.type_id == 3" name="folder" color="#6f42c1" width="25" height="25" class></BaseIcon>
        <div class="d-flex justify-content-between w-100">
          <div class="ml-2">
            <span class="mr-2">
              <strong>{{ file.identifiant }}</strong>
            </span>
            <span>{{ file.nom }}</span>
            <div class="text-muted" v-if="desc">
              <small>{{ file.description }}</small>
            </div>
          </div>
          <div class="text-muted">
            <small>Rev.{{ file.version }}</small>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <div class="pb-2 pl-2 pr-2">
        <div class="pl-1 pr-1 pl-md-4 pr-md-4">
          <div class="p-3 row flex-nowrap overflow-auto">
            <tab-item icon="file" title="Fichiers" @click="tabView = 'files'" :active="tabView === 'files'"></tab-item>
            <tab-item
              icon="settings"
              title="Paramètres"
              @click="tabView = 'settings'"
              :active="tabView === 'settings'"
            ></tab-item>
          </div>
        </div>
        <div v-show="tabView === 'files'">
          <div class="pl-1 pr-1 pl-md-4 pr-md-4">
            <uploader
              v-if="
                havePermission('get_document_qsser_word') ||
                file.type_id === 2 ||
                file.type_id === 3 ||
                file.type_id === 4
              "
              :id="'document_modifiable' + file.id"
              title="Documents Word, Excel ou PowerPoint"
              url="/upload/document"
              file_system="DOCS"
              file_field="word"
              :file_key="file.id"
              file_accepted=".docx, .doc, .xlsx, .xls, .ppt, .pptx"
            ></uploader>
            <uploader
              :id="'document_pdf' + file.id"
              title="Documents Pdf"
              url="/upload/document"
              file_system="DOCS"
              file_field="pdf"
              :file_key="file.id"
              file_accepted=".pdf"
            ></uploader>
          </div>
        </div>
        <div v-if="tabView === 'settings'">
          <base-form
            :data="file"
            @click="$emit('update', file)"
            @delete="$emit('delete', file)"
            :loading="loadingUpdate"
            deleteBtn
          >
            <base-form-group title="Paramètres" dense icon="settings">
              <base-form-row row>
                <base-select
                  inputText="Type"
                  v-model.number="file.type_id"
                  :options="contextData.types"
                  disabled
                ></base-select>
                <base-input-number v-model="file.numero" inputText="Numéro" disabled></base-input-number>
              </base-form-row>
              <base-form-row row>
                <base-input v-model="file.nom" inputText="Nom"></base-input>
                <base-input-number v-model="file.version" inputText="Version"></base-input-number>
              </base-form-row>
              <base-form-row row>
                <base-input-area v-model="file.description" text="Description"></base-input-area>
              </base-form-row>
              <base-form-row row class="mb-4">
                <Checkbox
                  id="1"
                  v-model="file.accessible_collabs"
                  text="Document accessible à tous les collaborateurs (via leur page personnelle)"
                  :iconActive="true"
                ></Checkbox>
              </base-form-row>
              <div v-background-1 class="p-3 rounded">
                <div>
                  <small>
                    Indiquez ici les raccourcis disponibles. Les documents seront ensuite accessibles via les liens sur
                    le panneau latéral.
                  </small>
                </div>
                <base-form-row row>
                  <vue-tags-input
                    placeholder="+ catégorie"
                    class="w-100 rounded"
                    v-model="tag"
                    :tags="categorieTags"
                    :autocomplete-items="filteredItemsCategories"
                    @tags-changed="(newTags) => tagChangedCategories(newTags)"
                    :add-only-from-autocomplete="true"
                  />
                </base-form-row>
                <base-form-row row>
                  <vue-tags-input
                    placeholder="+ activité"
                    class="w-100 rounded"
                    v-model="tag3"
                    :tags="activitesTags"
                    :autocomplete-items="filteredItemsActivites"
                    @tags-changed="(newTags) => tagChangedActivites(newTags)"
                    :add-only-from-autocomplete="true"
                  />
                </base-form-row>
                <base-form-row row>
                  <vue-tags-input
                    placeholder="+ raccourcis"
                    class="w-100 rounded"
                    v-model="tag2"
                    :tags="raccourcisTags"
                    :autocomplete-items="filteredItemsRaccourcis"
                    @tags-changed="(newTags) => tagChangedRaccourcis(newTags)"
                    :add-only-from-autocomplete="true"
                  />
                </base-form-row>
              </div>
            </base-form-group>
            <div class="ml-3 mb-3">
              <h5 class="mb-0">Pack accueil</h5>
              <div class="mb-3">
                <small
                  >Sélectionnez les packs accueil dans la liste ci-dessous. Les documents sélectionnés seront
                  téléchargeables par les collaborateurs affectés.
                </small>
              </div>
              <div v-for="accueil in contextData.accueils" :key="accueil.id" class="d-flex align-items-center">
                <button-circle
                  v-if="!inPackAccueil(accueil.id)"
                  @click="assignAccueil(accueil.id)"
                  name="circle"
                  size="18"
                  class="mr-1"
                ></button-circle>
                <button-circle
                  v-if="inPackAccueil(accueil.id)"
                  @click="revokeAccueil(accueil.id)"
                  name="check-circle"
                  size="18"
                  class="mr-1"
                ></button-circle>
                <span>{{ accueil.description }}</span>
              </div>
            </div>
          </base-form>
        </div>
      </div>
    </template>
  </container-list-extend>
</template>
<script>
import { mapGetters } from "vuex";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import Uploader from "@/components/bases/Uploader.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseSelect from "@/components/bases/Select.vue";
import TabItem from "@/components/bases/TabItem.vue";
import VueTagsInput from "@johmun/vue-tags-input";
import Checkbox from "@/components/bases/Checkbox.vue";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  name: "ListeItem",
  components: {
    ContainerListExtend,
    Uploader,
    BaseIcon,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
    BaseInput,
    BaseInputNumber,
    BaseInputArea,
    BaseSelect,
    TabItem,
    VueTagsInput,
    Checkbox,
    ButtonCircle,
  },
  props: {
    file: {
      Type: [Object, Array],
      required: false,
    },
    contextData: {
      Type: [Object, Array],
      required: false,
    },
    statut: {
      Type: String,
    },
    loadingUpdate: {
      Type: Boolean,
    },
  },
  computed: {
    ...mapGetters({ havePermission: "user/havePermission" }),

    categorieTags() {
      let d = this.file.categories;
      let t = [];
      let r = [];

      if (d == undefined) return [];
      t = d.split(";");

      t.forEach((e) => {
        if (e)
          r.push({
            text: e,
          });
      });

      return r;
    },
    raccourcisTags() {
      let d = this.file.raccourcis;
      let t = [];
      let r = [];

      if (d == undefined) return [];
      t = d.split(";");

      t.forEach((e) => {
        if (e)
          r.push({
            text: e,
          });
      });

      return r;
    },
    activitesTags() {
      let d = this.file.activites;
      let t = [];
      let r = [];

      if (d == undefined) return [];
      t = d.split(";");

      t.forEach((e) => {
        if (e)
          r.push({
            text: e,
          });
      });

      return r;
    },

    filteredItemsCategories() {
      /* return this.autocompleteItemsCategories.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      }); */
      return this.autocompleteItemsCategories;
    },
    filteredItemsRaccourcis() {
      /* return this.autocompleteItemsRaccourcis.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag2.toLowerCase()) !== -1;
      }); */
      return this.autocompleteItemsRaccourcis;
    },
    filteredItemsActivites() {
      /* return this.autocompleteItemsActivites.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag3.toLowerCase()) !== -1;
      }); */
      return this.autocompleteItemsActivites;
    },
  },
  data() {
    return {
      tabView: "files",
      desc: false,
      focusActivite: false,
      tag: "",
      tag2: "",
      tag3: "",
      autocompleteItemsCategories: [
        { text: "procedures" },
        { text: "formulaires" },
        { text: "supports" },
        { text: "trames" },
      ],
      autocompleteItemsRaccourcis: [
        { text: "organisation" },
        { text: "consignes" },
        { text: "sse" },
        { text: "radioprotection" },
        { text: "rh" },
        { text: "achat" },
        { text: "edf" },
        { text: "cea" },
        { text: "organigrammes" },
        { text: "embauche" },
        { text: "pub" },
        { text: "accidents" },
        { text: "non-conformites" },
        { text: "sser" },
        { text: "audit" },
        { text: "animation" },
        { text: "certificats" },
        { text: "referentiel" },
        { text: "kbis" },
        { text: "administratif_energy" },
        { text: "administratif_techno" },
        { text: "administratif_ndt" },
        { text: "administratif_si" },
        { text: "administratif_services" },
      ],
      autocompleteItemsActivites: [
        { text: "assistance_technique" },
        { text: "cnd" },
        { text: "etudes" },
        { text: "scan" },
      ],
    };
  },
  methods: {
    tagChangedCategories(val) {
      this.file.categories = val.map((e) => e.text).join(";");
    },
    tagChangedRaccourcis(val) {
      this.file.raccourcis = val.map((e) => e.text).join(";");
    },
    tagChangedActivites(val) {
      this.file.activites = val.map((e) => e.text).join(";");
    },
    inPackAccueil(accueil_id) {
      if (this.file.accueils.some((c) => c.accueil_id == accueil_id)) return true;
      return false;
    },
    assignAccueil(accueil_id) {
      this.$http
        .post("/documents_qsser_accueil/assign", {
          accueil_id: accueil_id,
          document_id: this.file.id,
        })
        .then((resp) => {
          this.file.accueils.push(resp.data);
        });
    },
    revokeAccueil(accueil_id) {
      this.$http
        .post("/documents_qsser_accueil/revoke", {
          accueil_id: accueil_id,
          document_id: this.file.id,
        })
        .then(() => {
          const index = this.file.accueils.findIndex((x) => x.accueil_id === accueil_id);
          this.file.accueils.splice(index, 1);
        });
    },
  },
};
</script>
<style>
.vue-tags-input {
  background: none !important;
  background-color: none !important;
  max-width: none !important;
}

.ti-input {
  border: none !important;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #b7c4c9;
}

.vue-tags-input .ti-input {
  transition: border-bottom 200ms ease;
}

.ti-tag {
  background-color: #007bff !important;
}

.ti-item {
  padding: 3px;
}

.ti-autocomplete {
  background-color: black !important;
  color: white;
  border-radius: 0.2rem;
  z-index: 2100 !important;
}

.ti-item.ti-valid.ti-selected-item {
  background-color: black !important;
  color: #007bff;
  border-radius: 0.2rem;
}
</style>
